<template>
  <div>
    <h2>请选择登录企业</h2>
    <div style="margin-top:50px" class="box">
        <el-card class="box-card card" v-for="item in multipleAccounts" :key="item.userId" @click.native="gettokenClick(item)"> {{item.partyName}}</el-card>
    </div>
    
  </div>
</template>

<script>
import {gettokens} from "@/api/index";
import {setToken,getPassword} from "@/utils/util"
export default {
  data() {
    return {
        // multipleAccounts:[]
    };
  },
  computed:{
    multipleAccounts: {
      get () { return this.$store.state.user.multipleAccounts }
    },
  },
  created() {
 },
  methods: {
    gettokenClick(item){
      gettokens({
          userId:item.userId,
          password:this.$store.state.user.password,
        }).then((data)=>{
          this.$store.commit("user/updatePassword","")
          if (data.code==320007) {
            this.$router.push({name:"changePassword",query:{ userId:item.userId,partyId:item.partyId}});
            return
          }
          if (data&&data.code==200) {
            localStorage.removeItem('partyName')
             localStorage.setItem('partyName', item.partyName);
             setToken(data.data.token)//存储token
             this.$router.push({name:"home"});
          }
        })
    }
  }
};
</script>

<style scoped>
h2 {
  text-align: center;
   margin-top: 100px;
}
.box{
    width: 90%;
    height: 500px;
    margin: auto;
    text-align: center;
}
.card{
    float: left;
    width: 30%;
    height: 100px;
    margin-top: 20px;
    margin-left: 3%;
   line-height: 30px;
}
.card :hover{
    height: 100px;
    background:#9E99B9;
    cursor:pointer;
    color: #ffff;
}
</style>


